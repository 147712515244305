<template>
  <div id="map"></div>
</template>

<script>
import BMap from "BMap";
export default {
  name: "Map",
  data() {
    return {
      show: false
    };
  },
  methods: {
    createMap() {
      var _this = this;
      /* eslint-disable */
      // 创建Map实例
      var map = new BMap.Map("map");
      // 初始化地图,设置中心点坐标和地图级别
      map.centerAndZoom(new BMap.Point(116.404, 39.915), 11);
      //添加地图类型控件
      map.addControl(
        new BMap.MapTypeControl({
          mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
        })
      );
      // 设置地图显示的城市 此项是必须设置的
      map.setCurrentCity("北京");
      map.addControl(new BMap.NavigationControl());
      map.addControl(new BMap.ScaleControl());
      map.addControl(new BMap.GeolocationControl());
      //开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true);
      /* eslint-enable */
      console.log(map);
      map.addEventListener("click", function(e) {
        map.clearOverlays();
        console.log(e.point.lng + ", " + e.point.lat);
        _this.showpoint(e.point.lng, e.point.lat);
        var point = new BMap.Point(e.point.lng, e.point.lat);
        var marker = new BMap.Marker(point); // 创建标注
        map.addOverlay(marker);
      });
    },
    showpoint(lng, lat) {
      var e = {
        lng: lng,
        lat: lat
      };
      this.$emit("getPoint", e);
    }
  },
  mounted() {
    this.createMap();
  }
};
</script>
<style lang="scss" scoped>
#map {
  width: 100%;
  height: 400px;
}
</style>
