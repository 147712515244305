<template>
  <div class="info">
    <Nav navType="backnav" textCon="选择地址"></Nav>
    <BMap @getPoint="getpoint"></BMap>
    <div class="bottom">
      <div class="button" @click="goto()">确定</div>
    </div>
  </div>
</template>

<script>
import Nav from "@components/public/searchTop";
import BMap from "@/components/public/bmap"
export default {
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    goto() {
      this.$router.go(-1)
    },
    getpoint(e) {
      console.log(e.lng, e.lat);
      sessionStorage.setItem('MapPoint',JSON.stringify(e))
    },
  },
  components: {
    Nav,
    BMap
  }
};
</script>
<style lang="scss" scoped>
.info {
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  padding-top: 45px;
}
.bottom {
  width: 100%;
  height: 48px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  .button {
    width: 92%;
    height: 36px;
    margin: 6px auto;
    border-radius: 18px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    font-size: 15px;
    background: linear-gradient(
      -90deg,
      rgba(255, 128, 0, 1) 0%,
      rgba(255, 177, 1, 1) 100%
    );
  }
}
</style>
